<template>
  <v-theme-provider dark>
    <base-section
      id="social"
      class="accent text-center"
    
    >
    <span v-intersect ="handleIntersect"></span>
      <base-section-heading
        color="transparent"
        title="Ready to get Started?"
      >
      </base-section-heading>

      <div class="py-4" />

      <v-btn
        class="font-weight-bold"
        color="white"
        href="https://icloud.service-now.com/recruitment"
        light
        min-width="168"
        target="_blank"
        x-large
      >
        Work with us

        <v-icon right>
          mdi-open-in-new
        </v-icon>
      </v-btn>
    </base-section>
  </v-theme-provider>
</template>

<script>
import { EventBus } from '../../event-bus';
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
    methods: {
      handleIntersect(entries, observer) {
			if(entries[0].isIntersecting) {
        EventBus.$emit('changeTab', "#theme-features" );
			}
		}
    }
  }
</script>
